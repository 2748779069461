import React from 'react';

import {
    container,
    title as titleClass,
    button as buttonClass,
    checkmark,
    description,
} from './info.module.scss';
import { ISectionContentButton } from '../../models/section.model';

import Title from '../atoms/title';
import Button from '../atoms/button';

interface IInfoRenderFunctionProps {
    checkmarkClassName: string;
    descriptionClassName: string;
}

export interface IInfoProps {
    className?: string;
    title: string | null;
    button?: ISectionContentButton | null;
    children?:
        | React.ReactNode
        | React.ReactNode[]
        | ((props: IInfoRenderFunctionProps) => React.ReactNode | React.ReactNode[]);
}

export default function Info({ title, button, children, className = '' }: IInfoProps) {
    return (
        <div className={[container, className].join(' ')}>
            <Title className={titleClass} Tag="h3">
                {title}
            </Title>
            {button && button.url && (
                <Button as="link" className={buttonClass} to={button.url}>
                    {button.text}
                </Button>
            )}
            {typeof children === 'function' &&
                children({ checkmarkClassName: checkmark, descriptionClassName: description })}
            {typeof children !== 'function' && children}
        </div>
    );
}
